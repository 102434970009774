import _ from "lodash";

export default function dateValid(dateStr) {
  if (dateStr && _.trim(dateStr) !== "") {
    const { year, month, day } = getDateParts(dateStr);
    const maxConstraint = year <= 2100 && month <= 12 && day <= 31;
    const minConstraint = year >= 1900 && month >= 1 && day >= 1;

    return minConstraint && maxConstraint;
  } else {
    return false;
  }
}

function getDateParts(dateStr) {
  const parts = _.split(dateStr, "-");
  const year = _.get(parts, "[0]");
  const month = _.get(parts, "[1]");
  const day = _.get(parts, "[2]");
  const yearNum = year ? _.toNumber(year) : null;
  const monthNum = month ? _.toNumber(month) : null;
  const dayNum = day ? _.toNumber(day) : null;

  return {
    year: yearNum,
    month: monthNum,
    day: dayNum,
  };
}
